import { Link } from "react-router-dom"

import "./App.css"

import AppList from "./apps/list"

const SUBTITLES = [
  "Quality software that no one asked for.",
  "Enterprise level development, potato level ideas.",
  "Lovingly handcrafted on MacBook Pro.",
  "Fine cuts of average code.",
  "Highly performant, rarely utilized.",
  "YANGI for mobile.",
  "Truly one of the developers of all time.",
]

const randomFromArray = (arr) => arr[Math.floor(Math.random() * arr.length)]

const Header = () => (
  <div className="header">
    <Link to="/">
      <img src="./favicon.ico" width="80" />
    </Link>

    <div>
      <div className="title">CHERRYNZ</div>
      <div className="subtitle">{randomFromArray(SUBTITLES)}</div>
    </div>
  </div>
)

const Footer = () => (
  <div className="footer">
    <div className="footer-card">
      <a
        className="contact-link"
        href="https://bsky.app/profile/chezdev.bsky.social"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="./bluesky.svg" className="icon" />
        @chezdev.bsky.social
      </a>

      <a
        className="contact-link"
        href="mailto:contact@cherrynz.net"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src="./email.svg" className="icon" />
        CONTACT@CHERRYNZ.NET
      </a>
    </div>
  </div>
)

const App = () => (
  <div>
    <Header />
    <div className="container">
      <AppList />
    </div>

    <Footer />
  </div>
)

export default App
